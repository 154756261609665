<template>
    <div class="pagination-wrapper">
        <vs-pagination :total="total" v-model="currentx"></vs-pagination>
    </div>
</template>
<script>
    export default {
      name: "Paginantions",
      props: {
        total: Number
      },
      data () {
          return {
            currentx: 1
          }
      },
      watch: {
        currentx () {
          this.$emit('handlePagination', this.currentx)
        }
      }
    }
</script>