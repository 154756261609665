<template>
<div class="table-content">
        <div class="table-options">
            <div class="delete" v-if="selected.length > 0">
                <vs-icon icon="delete"></vs-icon>
            </div>
        </div>
        <div class="table-wrapper with-border">
            <vs-table :data="bookings">
                <template slot="thead">

                    <!-- "AgentName": "C&C Travel",
                    "SupplierCode": "GRAORO",
                    "SupplierName": "Grano de Oro Hotel",
                    "BkgStatus": "Quote",
                    "SrvStatus": "Initial Status",
                    "FullOptionCode": "SJOACGRAORODELUXE",
                    "Cantidad": 1,
                    "COST": 288.15,
                    "AGENT": 1176.7,
                    "Markup": 888.55,
                    "DESCRIPTION": "Deluxe Room",
                    "COMMENT": "Breakfast Included",
                    "SrvType": "Accommodation" -->

                    <vs-th sort-key="SRVCODE"> Tipo  </vs-th>
                    <vs-th sort-key="DESCRIPTION"> Servicio  </vs-th>
                    <vs-th sort-key="COMMENT"> COMMENT  </vs-th>
                    <vs-th sort-key="SrvStatus">Service Status</vs-th>
                    <vs-th sort-key="SupplierCode">Proveedor</vs-th>
                    <vs-th sort-key="AgentCode">Agencia</vs-th>
                    <vs-th sort-key="BkgStatus">Bk_Status</vs-th>
                    <vs-th sort-key="Cantidad" class="center">Qty</vs-th>
                    <vs-th sort-key="COST">COST</vs-th>
                    <vs-th sort-key="Markup">Markup </vs-th>
                    <vs-th sort-key="AGENT">AGENT</vs-th>
                    <!-- <vs-th sort-key="id"></vs-th> -->
                </template>
                <template slot-scope="{ data }">
                    <template :data="tr"  v-for="(tr, indextr) in data">
                        <vs-tr :key="indextr" :class="{'active': opened.includes(data[indextr].id)}">
                            <vs-td :data="data[indextr].SRVCODE"> 
                               <img class="icon-table" :src="require(`@/assets/images/booking/${data[indextr].SRVCODE}.svg`)" alt="" />
                            </vs-td>
                            <vs-td :data="data[indextr].DESCRIPTION"> {{ data[indextr].DESCRIPTION }} </vs-td>
                            <vs-td :data="data[indextr].COMMENT"> {{ data[indextr].COMMENT }} </vs-td>
                            <vs-td :data="data[indextr].SrvStatus"> {{ data[indextr].SrvStatus }} </vs-td>
                            <vs-td :data="data[indextr].SupplierCode"> {{ data[indextr].SupplierCode }} </vs-td>
                            <vs-td :data="data[indextr].AgentCode"> {{ data[indextr].AgentCode }} </vs-td>
                            <vs-td :data="data[indextr].BkgStatus"> {{ data[indextr].BkgStatus }} </vs-td>
                            <vs-td :data="data[indextr].Cantidad" class="center"> {{ data[indextr].Cantidad }} </vs-td>
                            <vs-td :data="data[indextr].COST"   class="pending"> {{ $n(data[indextr].COST, 'currency') }} </vs-td>
                            <vs-td :data="data[indextr].Markup" class="default"> {{ $n(data[indextr].Markup, 'currency') }} </vs-td>
                            <vs-td :data="data[indextr].AGENT" class="success"> {{ $n(data[indextr].AGENT, 'currency') }} </vs-td>
                            <!-- <vs-td class="options">
                                <vs-icon icon="expand_more" @click="toggleRow(indextr)"></vs-icon>
                            </vs-td>  -->
                        </vs-tr>
                    </template>
                </template>
                <tr>
                    <td colspan=""></td>
                </tr>
            </vs-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableBookingList",
    props: {
        items: { type: Array, default: () => [] },
    },
    data () {
        return {
            opened: [],
            selected:[],
            bookings: this.items,
        }
    },
    watch: {
        items(val){
            this.bookings = val
        }
    },
    methods: {
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        },
    }
}
</script>